import React from "react"
import { GridBox, Text, Title } from "@thedgbrt/logology-ui-components"
import SEO from "../../components/seo"

const SupportPage = () => (
  <>
    <SEO title="Support" />
    <GridBox type={5} colSpans={[[2, 4], [2, 4], [3, 6]]} withBgContainer>
      <Title standalone>Support</Title>
    </GridBox>

    <GridBox type={5} colSpans={[[2, 7], [2, 7], [4, 8]]} withBgContainer>
      <Text standalone italic>
        To get help or if you have any questions, please contact us at{" "}
        <a href="mailto:team@logology.co">team@logology.co</a>.
      </Text>
    </GridBox>
  </>
)

export default SupportPage
